.Measures .btn {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
} 

.Date .btn {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
} 

@media only screen and (max-width: 600px) {
    .Measures {
        align-items: flex-start;
        margin-top: 1rem;
        border-top: gray solid 1px;
        border-bottom: gray solid 1px;
    }
    .Date {
        align-items: flex-end;
    }
    .Measures .btn {
        margin: 0.5rem;
    } 
    
    .Date .btn {
        margin: 0.5rem;
    } 
}