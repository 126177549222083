.Map{
    border: solid 2px rgb(129, 129, 129);
    position: fixed;
}

@media only screen and (min-width: 600px) {
    .mapboxgl-ctrl-icon {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='60' height='60' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E %3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 0 0 5.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 0 0 9 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 0 0 3.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0 0 11 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7z'/%3E %3Ccircle id='dot' cx='10' cy='10' r='2'/%3E %3Cpath id='stroke' d='M14 5l1 1-9 9-1-1 9-9z' display='none'/%3E %3C/svg%3E") !important;
    }
    .mapboxgl-ctrl-group button{
        width: 60px !important;
        height: 60px !important;
    }
    .mapboxgl-ctrl-top-left {
        top: 25px  !important;
        left: 25px !important;
    }
}
.mapboxgl-marker{
    cursor: pointer !important;
}