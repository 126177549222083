.loader{
	margin:5rem;
	display: grid;
	justify-content: center;
	align-items: center;
}
.card {
	border-color: #fff;
	border-radius: 1rem !important;
	padding: 1rem;
	margin: 1rem 0px;
	box-shadow: 0 0.4rem 0.4rem rgba(50,50,93,.05), 0 0.4rem 1rem rgba(0,0,0,.05);
}

.card.blue {
	color: #fff;
	border-color: #4562ec;
	background-color: #4562ec;
}

.center-pie-data {
	position: relative;
	z-index: 5;
	
}

.card-mid text{
	font-size: 0.9em;
	font-weight: bold;
}

.card-mid p{
	text-align: center;
	font-size: 0.9rem;
	color: #6a8291;
}


.data-container {
	display: flex;
	align-items: center;
}

.card.linechart .previous-data{
	margin-left: 2rem;
}

.previous-data{
	display: grid;
	justify-content: center;
	align-items: center;
}

.current-data{
	margin-left: 1rem;
} 

p.stat {
	text-align: center;
	font-weight: 700;
	color: #6a8291;
	margin-top: 0.5rem;
	margin-bottom: -0.5rem;
}

.stat-data {
	font-size: 1.5em;
	letter-spacing: 0.05rem;
}

.stat-data.current {
	color: #406ae2;
}

.stat-data.previous, .stat-data.gross, .stat-data.online {
	color: #a6b4bf;
}

.gross-data, .online-data, .sold-data {
	flex: 1;
	text-align: center;
}

.card.pie .data-container{
	display: flex;
}

.card-top {
    display: flex;
	justify-content: center;
	text-align: center;
	margin-top: 1rem;
	margin-left: 1rem;
	margin-right: 1rem;
	margin-bottom: 1rem;
}

.card-top h2{
	font-weight: bold;
}

.card.blue p{
	padding-bottom: 0px;
}

.top-drop {
	flex: 1;
}

.main-content {
    padding: 1rem;
	color: #6a8291;
	background-color: #f7f8fc;
}
.pie-center {
    position: absolute;
    z-index: 10;
    top: 40%;
    left: 40%;
}
.sold-data .total {
	color: #a6b4bf;
	font-weight: 700;
}
.sold-data .stat-data {
	color: #4561ed;
}

@media only screen and (max-width: 600px) {
    .card {
        border-color: #fff;
        border-radius: 1rem !important;
        padding: 0.6rem;
        margin: 0.2rem 0px;
        box-shadow: 0 0.1rem 0.1rem rgba(50,50,93,.05), 0 0.1rem 0.5rem rgba(0,0,0,.05);
    }
    .main-content {
        padding: 0px;
        color: #6a8291;
        background-color: #f7f8fc;
    }
}