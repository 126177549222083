.footer{
    margin-top: 1rem;
    padding: 1rem;
    background-color: #6a8291;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}
.footerContent{
    color: white;
    text-align: center;
}
.footerContent p{
    margin-top: 1em;
    font-size: 1.5em;
}